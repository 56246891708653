import './style/global.less';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { ConfigProvider } from '@arco-design/web-react';
import zhCN from '@arco-design/web-react/es/locale/zh-CN';
import enUS from '@arco-design/web-react/es/locale/en-US';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import rootReducer from './store';
import PageLayout from './layout';
import { GlobalContext } from './context';
import Login from './pages/login';
import checkLogin from './utils/checkLogin';
import changeTheme from './utils/changeTheme';
import useStorage from './utils/useStorage';
import { QueryClient, QueryClientProvider } from 'react-query';

const store = createStore(rootReducer);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 60
    }
  }
});

function Index() {
  const [lang, setLang] = useStorage('arco-lang', 'zh-CN');
  const [theme, setTheme] = useStorage('arco-theme', 'dark');

  function getArcoLocale() {
    switch (lang) {
      case 'zh-CN':
        return zhCN;
      case 'en-US':
        return enUS;
      default:
        return zhCN;
    }
  }

  useEffect(() => {
    if (checkLogin()) {
    } else if (window.location.pathname.replace(/\//g, '') !== 'login') {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.pathname = '/login';
    }
  }, []);

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  const contextValue: object = {
    lang,
    setLang,
    theme,
    setTheme
  };

  return (
    <BrowserRouter>
      <QueryClientProvider client={client}>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
        <ConfigProvider locale={getArcoLocale()}>
          <Provider store={store}>
            <GlobalContext.Provider value={contextValue}>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/" component={PageLayout} />
              </Switch>
            </GlobalContext.Provider>
          </Provider>
        </ConfigProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));
