import { Button, Form, Grid, Image, Input, Space } from '@arco-design/web-react';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, { useEffect, useState } from 'react';
import styles from './style/index.module.less';
import { request, Res } from '@/utils/request';

export default function LoginForm() {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  function login() {
    setErrorMessage('');
    setLoading(true);
    console.log(form.getFieldsValue());
    request
      .post('/admin/login', form.getFieldsValue())
      .then((res: Res) => {
        const { code, data, msg } = res;
        if (code == 0) {

          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('token', data.token);

          window.location.href = '/index';

        } else {
          setErrorMessage(msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const [captchaUrl, setCaptchaUrl] = useState('');
  const getCaptcha = () => {
    request.get('/admin/captcha').then((res: Res) => {
      const url = res.data.url;

      //验证码key
      const key = url.split('/captcha/')[1].split('.')[0];
      form.setFieldValue('captcha_key', key);

      setCaptchaUrl(res.data.url);
    });
  };

  // 读取 localStorage，设置初始值
  useEffect(() => {
    getCaptcha();
  }, []);


  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>北极星公考报名系统</div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form
        className={styles['login-form']}
        layout="vertical"
        onSubmit={login}
        form={form}
      >
        <Form.Item
          field="username"
          rules={[{ required: true, message: '请输入账号' }]}
        >
          <Input
            prefix={<IconUser />}
            placeholder={'请输入账号'}
          />
        </Form.Item>
        <Form.Item
          field="password"
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input.Password
            prefix={<IconLock />}
            placeholder={'请输入密码'}
          />
        </Form.Item>
        <Grid.Row>
          <Grid.Col span={16}>
            <Form.Item
              field="captcha"
            >
              <Input />
            </Form.Item>
          </Grid.Col>
          <Grid.Col span={8}>
            <Image
              src={captchaUrl}
              preview={false}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                getCaptcha();
              }} />
          </Grid.Col>
        </Grid.Row>
        <Form.Item field={'captcha_key'} hidden={true}>
          <Input />
        </Form.Item>
        <Space size={16} direction="vertical">
          <Button type="primary" long htmlType={'submit'} loading={loading}>
            登录
          </Button>
        </Space>
      </Form>
    </div>
  );
}
