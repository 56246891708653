import axios, { AxiosResponse } from 'axios';
import { Message } from '@arco-design/web-react';

const ACCESS_TOKEN = 'token';
const api_url = {
  'product': 'https://api.baoming.funengyun.cn/api',
  // 'product': 'https://api.sxbjxjy.cn/api',
  'dev': 'https://api.baoming.funengyun.cn/api'
};

//接口地址
const baseURL = process.env.NODE_ENV === 'development' ? api_url.dev : api_url.product;
const uploadUrl = `${baseURL}/admin/upload`;
//静态资源url
const request = axios.create({
  baseURL,
  headers: {
    'accept': 'application/json'
  },
  timeout: 60000
});

const token = localStorage.getItem(ACCESS_TOKEN);
request.interceptors.request.use((config) => {
  token && (config.headers[ACCESS_TOKEN] = token);
  return config;
});

interface Res extends AxiosResponse {
  code: number;
  msg: string;
  data: any;
}

request.interceptors.response.use((response: Res) => {
  const err_msg = response.data.msg || '服务器错误';
  if (response.data.code === -9999) {
    Message.error(err_msg);
    window.location.href = '/login';
  }
  return response.data as Res;
});
export { request, baseURL, uploadUrl, token };
export type { Res };
