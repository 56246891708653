// https://stackoverflow.com/questions/68424114/next-js-how-to-fetch-localstorage-data-before-client-side-rendering
// 解决 nextJS 无法获取初始localstorage问题

import { useEffect, useState } from 'react';
import { isSSR, isString } from '@/utils/is';

const getDefaultStorage = (key: string) => {
  if (!isSSR) {
    const data = localStorage.getItem(key);
    let value = null;
    try {
      value = JSON.parse(data);
    } catch (e) {
      value = data;
    }
    return value;
  } else {
    return undefined;
  }
};

function useStorage(key: string, defaultValue?: string | object): [
    string | object,
  (value: string | object) => void,
  () => void
] {
  const [storedValue, setStoredValue] = useState(
    getDefaultStorage(key) || defaultValue
  );

  const setStorageValue = (value: string | object) => {
    if (!isSSR) {
      const data = !isString(value) ? JSON.stringify(value) : value as string;
      localStorage.setItem(key, data);
      if (value !== storedValue) {
        setStoredValue(value);
      }
    }
  };

  const removeStorage = () => {
    if (!isSSR) {
      localStorage.removeItem(key);
    }
  };

  useEffect(() => {
    const storageValue = localStorage.getItem(key);
    if (storageValue) {
      setStoredValue(storageValue);
    }
  }, []);

  return [storedValue, setStorageValue, removeStorage];
}

export default useStorage;
