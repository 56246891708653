import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Image, Menu, Tooltip } from '@arco-design/web-react';
import { IconMoonFill, IconPoweroff, IconSunFill } from '@arco-design/web-react/icon';
import Logo from '@/assets/logo.jpg';
import styles from './style/index.module.less';
import { request, Res } from '@/utils/request';
import IconButton from './IconButton';
import { GlobalContext } from '@/context';

function Navbar({ show }: { show: boolean }) {

  const [user, setUser] = useState({ username: '' });
  const { theme, setTheme } = useContext(GlobalContext);

  function logout() {
    request.post('/admin/logout').then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    });
  }

  function getUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    setUser(user);
  }

  function checkLogin() {
    request.post('/admin/checkLogin').then((res: Res) => {
      if (res.code != 0) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
    });
  }

  useEffect(() => {
    checkLogin();
    getUser();
  }, []);

  function onMenuItemClick(key) {
    if (key === 'logout') {
      logout();
    }
  }

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <Image src={Logo} width={32} height={32} preview={false} />
          <div className={styles['logo-name']}>公考报名系统</div>
        </div>
      </div>
      <ul className={styles.right}>
        <li>
          <Tooltip
            content={
              theme === 'light'
                ? '点击切换为暗色模式'
                : '点击切换为亮色模式'
            }
          >
            <IconButton
              icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            />
          </Tooltip>
        </li>
        <li>
          <Dropdown droplist={droplist} position="br">
            <Button type={'default'}>{user.username}</Button>
          </Dropdown>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
