import { useContext } from 'react';
import { GlobalContext } from '@/context';

function useLocale(locale = null) {
  const { lang } = useContext(GlobalContext);

  return (locale || 'zh-CN')[lang] || {};
}

export default useLocale;
