import auth, { AuthParams } from '@/utils/authentication';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  IconApps,
  IconCalendar,
  IconCommon,
  IconDragDot,
  IconEar,
  IconFileImage,
  IconHome,
  IconPalette,
  IconPushpin,
  IconSkin,
  IconStamp,
  IconStorage,
  IconSubscribeAdd,
  IconSun,
  IconTool,
  IconUser
} from '@arco-design/web-react/icon';

export type IRoute = AuthParams & {
  name: string;
  key: string;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
  icon?: ReactNode
};

export const routes: IRoute[] = [
  {
    name: '首页',
    key: 'index',
    icon: <IconHome />
  },
  {
    name: '课程管理',
    key: 'course',
    icon: <IconCalendar />,
    children: [
      {
        name: '讲师管理',
        key: 'course/lecturer',
        icon: <IconEar />
      },
      {
        name: '课程分类管理',
        key: 'course/category',
        icon: <IconApps />
      },
      {
        name: '课程管理',
        key: 'course/index',
        icon: <IconDragDot />
      },
      {
        name: '课程排期',
        key: 'course/plan',
        icon: <IconCommon />
      },
      {
        name: '班型管理',
        key: 'course/classType',
        icon: <IconSubscribeAdd />
      }
    ]
  },
  {
    name: '报名管理',
    key: 'courseRegistration/index',
    icon: <IconStamp />,
    children: [
      {
        name: '报名详情',
        key: 'courseRegistration/info',
        ignore: true
      }
    ]
  },
  {
    name: '宿舍管理',
    key: 'dormitory',
    icon: <IconSkin />,
    children: [
      {
        name: '宿舍分类管理',
        key: 'dormitory/category',
        icon: <IconStorage />
      },
      {
        name: '宿舍布局模板',
        key: 'dormitory/template',
        ignore: true
      },
      {
        name: '宿舍管理',
        key: 'dormitory/dormitory',
        icon: <IconPushpin />
      },
      {
        name: '宿舍床位管理',
        key: 'dormitory/dormitory_bed',
        ignore: true
      }
    ]
  },
  {
    name: '教室管理',
    key: 'classroom/index',
    icon: <IconSun />,
    children: [
      {
        name: '教室布局设置',
        key: 'classroom/layout',
        ignore: true
      }
    ]
  },
  {
    name: '班主任管理',
    key: 'teacher',
    icon: <IconUser />
  },
  {
    name: '销售管理',
    key: 'sales',
    icon: <IconUser />
  },
  {
    name: '用户管理',
    key: 'users',
    icon: <IconUser />
  },
  {
    name: '轮播图管理',
    key: 'banner',
    icon: <IconFileImage />
  },
  {
    name: '系统设置',
    key: 'config',
    icon: <IconTool />,
    children: [
      {
        name: '地区管理',
        key: 'config/area',
        icon: <IconPalette />
      }
    ]
  }
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

const useRoute = (userPermission): [IRoute[], string] => {
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);

  useEffect(() => {
    const newRoutes = filterRoute(routes);
  }, [JSON.stringify(userPermission)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      return first?.children?.[0]?.key || first.key;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
